import {
  NetworkInfo,
  BestNetworkDot,
  GoodNetworkDot,
  BadNetworkDot,
  FooterCopyright,
  StyledFooterBtn,
  StyledFooterLink,
  StyledFooterNav,
  StyledNav,
  StyledSidebar,
} from './styles';

import Button from 'components/mediabay-ui/components/Button';
import Footer from './Footer';
import Icon from 'components/mediabay-ui/components/Icon';
import { Link } from 'react-router-dom';
import { Loc } from 'redux-react-i18n';
import MenuLink from 'components/mediabay-ui/components/MenuLink';
import PropTypes from 'prop-types';
import React from 'react';
import axios from 'axios';
import config from '../../config';
import isUzb from '../../utils/checkCountry';
import { version } from '../../../package.json';
class Sidebar extends React.Component {
  static propTypes = {
    collapsed: PropTypes.bool,
    switchLangHandler: PropTypes.func.isRequired,
    sidebarSectionClickHandler: PropTypes.func.isRequired,
  }

  static defaultProps = {
    collapsed: false,
  }

  constructor() {
    super();

    this.state = {
      userIp: null,
      country: null,
      effectiveType: null,
    };

    this.container = React.createRef();
    this.networkTimer = null;
  }

  componentDidMount() {
    const { host } = window.location;
    const {
      DEV_UZ_HOST,
      UZ_HOST,
      DEV_TV_HOST,
      TV_HOST,
      DEV_UZ_API_ENDPOINT,
      UZ_API_ENDPOINT,
      DEV_TV_API_ENDPOINT,
      TV_API_ENDPOINT,
    } = config;
    let ipUrl = '';

    // Handle API ENDPOINT for dev/prod and .uz/.tv servers
    switch(host) {
      case DEV_UZ_HOST:
        ipUrl = `//${DEV_UZ_API_ENDPOINT}`;
        break;
      case UZ_HOST:
      case `www.${UZ_HOST}`:
        ipUrl = `//${UZ_API_ENDPOINT}`;
        break;
      case DEV_TV_HOST:
        ipUrl = `//${DEV_TV_API_ENDPOINT}`;
        break;
      case TV_HOST:
      case `www.${TV_HOST}`:
        ipUrl = `//${TV_API_ENDPOINT}`;
        break;
      default:
        ipUrl = `//${UZ_API_ENDPOINT}`;
    }

    if (process.env.NODE_ENV === 'production') {
      axios.get(`//${ipUrl}/communicate`)
      .then((res) => {
        this.setState({
          userIp: res.data.communicate.user_ip,
          country: res.data.communicate.country,
        });
      });

      if (navigator.connection) {
        // Initial connection type
        this.setState({ effectiveType: navigator.connection.effectiveType });

        this.networkTimer = setInterval(() => {
          this.setState({ effectiveType: navigator.connection.effectiveType });
        }, 60000);
      }
    }
  }

  componentWillUnmount() {
    if (this.networkTimer) {
      clearInterval(this.networkTimer);
    }
  }

  renderConnectionType() {
    const { effectiveType } = this.state;

    if (!effectiveType) return;

    if (effectiveType === 'slow-2g' || effectiveType === '2g') {
      return (
        <NetworkInfo>
          <span> <Loc locKey="connection" /> (beta): <BadNetworkDot /> 2G</span>
        </NetworkInfo>
      )
    }

    if (effectiveType === '3g') {
      return (
        <NetworkInfo>
          <span> <Loc locKey="connection" /> (beta): <GoodNetworkDot /> 3G</span>
        </NetworkInfo>
      )
    }

    if (effectiveType === '4g') {
      return (
        <NetworkInfo>
          <span> <Loc locKey="connection" /> (beta): <BestNetworkDot /> 4G</span>
        </NetworkInfo>
      )
    }
  }

  render() {
    const { userIp, country } = this.state;
    const {
      collapsed,
      sidebarSectionClickHandler,
      switchLangHandler,
    } = this.props;

    return (
      <StyledSidebar
        ref={this.container}
        collapsed={collapsed}
        onClick={sidebarSectionClickHandler}
        {...this.props}
      >
        <StyledNav>
          <MenuLink to="/tv" icon="tv">
            <Loc locKey="tv" />
          </MenuLink>
          <MenuLink to="/movies" icon="movies">
            <Loc locKey="movies" />
          </MenuLink>
          <MenuLink to="/serials" icon="serials">
            <Loc locKey="serials" />
          </MenuLink>
          {isUzb() && (
            <MenuLink to="/cartoons" icon="cartoons">
              <Loc locKey="cartoons" />
            </MenuLink>
          )}
          {/* <MenuLink to="/clips" icon="clips">
            <Loc locKey="clips" />
          </MenuLink> */}
          <MenuLink to="/podcasts" icon="podcasts">
            <Loc locKey="podcasts" />
          </MenuLink>
          {/* <MenuLink to="/music" icon="music">
            <Loc locKey="music" />
          </MenuLink> */}
          <MenuLink to="/radio" icon="radio">
            <Loc locKey="radio" />
          </MenuLink>
          {/* {isUzb() && (
            <MenuLink to="/audiobooks" icon="audio-books">
              <Loc locKey="audioBooks" />
            </MenuLink>
          )} */}
          {isUzb() && (
            <MenuLink to="/vouchers/all" icon="star">
              <Loc locKey="subscribeAction" />
            </MenuLink>
          )}
          {/* <MenuLink to="/profile/favorites" icon="favorites">
            <Loc locKey="favorites" />
          </MenuLink> */}
        </StyledNav>
        <Footer>
          <Link to="/apps">
            <Button type="primary" offset={10} fullWidth>
              <Icon icon="download" style={{ marginRight: 5 }} />
              <Loc locKey="apps" style={{ fontSize: 16 }} />
            </Button>
          </Link>
          <StyledFooterNav>
            {isUzb() && (
              <StyledFooterLink to="/article/4">
                <Loc locKey="about" />
              </StyledFooterLink>
            )}
            {isUzb() && (
              <StyledFooterLink to="/licenses">
                <Loc locKey="licenses" />
              </StyledFooterLink>
            )}
            {!isUzb() && (
              <StyledFooterLink to="/privacy">
                <Loc locKey="policy" />
              </StyledFooterLink>
            )}
            <StyledFooterLink to={`${isUzb() ? '/article/3' : '/article/340'}`}>
              <Loc locKey="rules" />
            </StyledFooterLink>
            {isUzb() && (
              <StyledFooterLink to="/contacts">
                <Loc locKey="contacts" />
              </StyledFooterLink>
            )}
            {isUzb() && (
              <StyledFooterLink to="/ad" style={{ color: '#fff', display: 'block', width: '100%' }}>Реклама</StyledFooterLink>
            )}
          </StyledFooterNav>

          <StyledFooterNav>
            <StyledFooterBtn onClick={() => switchLangHandler('ru')}>
              Русский
            </StyledFooterBtn>
            <StyledFooterBtn onClick={() => switchLangHandler('uz')}>
              O&#39;zbek
            </StyledFooterBtn>
            {!isUzb() && (
              <React.Fragment>
                <StyledFooterBtn onClick={() => switchLangHandler('tj')}>
                  Тоҷикӣ
                </StyledFooterBtn>

                <StyledFooterBtn onClick={() => switchLangHandler('az')}>
                  Azeri
                </StyledFooterBtn>
              </React.Fragment>
            )}
            <StyledFooterBtn onClick={() => switchLangHandler('en')}>
              English
            </StyledFooterBtn>
          </StyledFooterNav>

          <FooterCopyright>
            <span>{`${new Date().getFullYear()}, `}</span>
            {isUzb() ? <Loc locKey="copyrightUz" /> : <Loc locKey="copyrightTv" />}
          </FooterCopyright>
          <FooterCopyright>
            <Loc locKey="yourIp" />
            {` ${userIp || 'неизвестно'}`}
          </FooterCopyright>
          <FooterCopyright>
            <Loc locKey="yourCountry" />
            {` ${country || 'неизвестно'}`}
          </FooterCopyright>
          <FooterCopyright>
            {this.renderConnectionType()}
          </FooterCopyright>
          <FooterCopyright>
            {version}
          </FooterCopyright>
        </Footer>
      </StyledSidebar>
    );
  }
}

export default Sidebar;
